import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Label,
} from 'reactstrap';
import Select from 'react-select';

// ** Components
import NoOption from '../../../components/examod/emptyList/NoOption';

// ** Utility
import { isNullOrEmpty } from '../../../utility/collectionUtil';
import { selectThemeColors } from '../../../utility/themeUtil';

const CourseSelection = ({
  courses,
  setSelectedCourse,
  selectedCourse,
  isSubmitted,
  getCreateQuestionMeta,
  resetCourseRelatedFields,
  disabledCourseSelection,
  setInputTouched,
}) => {
  const intl = useIntl();
  const reviseCourseList = () => {
    return courses.map((course) => ({
      label: ` ${course.name} (#${course.friendlyId})`,
      value: course.id,
    }));
  };

  const onChangeCourse = (option) => {
    if (setInputTouched) {
      setInputTouched(true);
    }
    if (resetCourseRelatedFields) {
      resetCourseRelatedFields();
    }
    setSelectedCourse(option);
    if (option) {
      getCreateQuestionMeta(option?.value);
    }
  };

  return (
    <Card className="h-100 add-question-custom">
      <CardBody className="pt-0">
        <CardHeader className="d-block pl-0 mb-2">
          <CardTitle>
            <FormattedMessage id={'questionAdd.courseInfoTitle'} />
          </CardTitle>
          <CardSubtitle className="mt-0">
            <FormattedMessage id={'questionAdd.courseInfoSubtitle'} />
          </CardSubtitle>
        </CardHeader>
        <Label>
          <span className="text-danger">*</span>
          <FormattedMessage id={'questionAdd.course'} />{' '}
        </Label>
        <Select
          isClearable
          theme={selectThemeColors}
          placeholder={intl.formatMessage({
            id: 'questionAdd.select',
          })}
          className={`basic-single, react-select ${
            isSubmitted && isNullOrEmpty(selectedCourse) ? 'is-invalid' : ''
          }`}
          classNamePrefix="select"
          options={reviseCourseList()}
          noOptionsMessage={NoOption}
          isDisabled={disabledCourseSelection}
          value={selectedCourse}
          onChange={(option) => {
            onChangeCourse(option);
          }}
        />

        {isSubmitted && isNullOrEmpty(selectedCourse) ? (
          <span className="text-error">
            <FormattedMessage id="questionAdd.requiredMessage" />
          </span>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default CourseSelection;
