export default {
  'questionAdd.title': 'Soru Oluştur',
  'questionAdd.duplicateTitle': 'Soru Çoğalt',
  'questionAdd.topics': 'Konular',
  'questionAdd.id': 'ID',
  'questionAdd.root': 'Soru kökü',
  'questionAdd.options': 'Seçenekler',
  'questionAdd.addOption': 'Seçenek ekle',
  'questionAdd.addOptionError': 'Seçenek sayısı en fazla 10 olabilir.',
  'questionAdd.categories': 'Soru kategorileri',
  'questionAdd.keywords': 'Anahtar kelimeler',
  'questionAdd.duration': 'Önerilen süre',
  'questionAdd.objectives': 'Öğrenme hedefi',
  'questionAdd.source': 'Doğru yanıtın kaynağı',
  'questionAdd.required': ' ',
  'questionAdd.save': 'Kaydet',
  'questionAdd.lock': 'Onayla ve Kilitle',
  'questionAdd.none': 'Yok',
  'questionAdd.30s': '30s',
  'questionAdd.1m': '1d',
  'questionAdd.2m': '2d',
  'questionAdd.3m': '3d',
  'questionAdd.4m': '4d',
  'questionAdd.5m': '5d',
  'questionAdd.10m': '10d',
  'questionAdd.correctOption': '(Doğru seçenek işaretlenmelidir)',
  'questionAdd.warning':
    'Uyarı! Lütfen doldurulması zorunlu olan alanları doldurunuz.',
  'questionAdd.answerWarning': 'Bir doğru yanıt seçmek zorundasınız!',
  'questionAdd.duplicateWarning':
    'Soru üzerinde bir değişiklik yapılmadığı için yeni soru eklenmemiştir.',
  'questionAdd.success': 'Soru başarıyla oluşturuldu!',
  'questionAdd.successLock': 'Soru başarıyla kilitlendi!',
  'questionAdd.requiredMessage': 'Bu alan boş bırakılamaz.',
  'questionAdd.editorMaxMessage': 'Soru kökü en fazla 5000 karakter olmalıdır.',
  'questionAdd.expectedAnswerMaxMessage':
    'Beklenen yanıt en fazla 5000 karakter olmalıdır.',
  'questionAdd.editorMinMessage': 'Soru kökü en az 15 karakter olmalıdır.',
  'questionAdd.topicPlaceholder': 'Yeni oluştur veya seç',
  'questionAdd.select': 'Seçiniz',
  'questionAdd.maxMessage':
    'Seçeneğin içeriği en fazla 400 karakter olmalıdır.',
  'questionAdd.minMessage': 'Seçeneğin içeriği en az 1 karakter olmalıdır.',
  'questionAdd.create': 'Oluştur ',
  'questionAdd.answerSourceMax':
    'Cevap kaynağı en fazla 200 karakter olmalıdır.',
  'questionAdd.attributeInfo': 'Soru özellikleri',
  'questionAdd.uncategorized': 'Sınıflandırılmamış',
  'questionAdd.questionDetailsTitle': 'Soru Detaylar',
  'questionAdd.questionDetailsSubtitle': 'Sorunun tüm detaylarını tanımlayın',
  'questionAdd.questionContentTitle': 'Soru İçeriği',
  'questionAdd.questionContentSubtitle': 'Sorunun içeriğini hazırlayın',
  'questionAdd.attachments': 'Ekler',
  'questionAdd.scientificCalculator': 'Bilimsel hesap makinesi',
  'questionAdd.addQuestionTools': 'Soru Araçları Ekle',
  'questionAdd.attachmentBrowse': 'Dosyalara gözat',

  'questionAdd.attachmentDropHere': 'Dosyaları buraya sürükleyip bırakın',
  'questionAdd.attachmentOr': 'YA DA',
  'questionAdd.attachmentRestrictionSize': "Ekler 64mb'yi geçmemelidir.",
  'questionAdd.attachmentRestrictionType':
    'Desteklenen dosya türleri: MP4, MP3',
  'questionAdd.attachmentTypeWarning':
    'Sadece MP4, MP3 tipindeki dosyaları yükleyebilirsiniz.',
  'questionAdd.attachmentSizeWarning':
    'Eklediğiniz dosya sistemin izin verdiğinden daha büyük.',
  'questionAdd.attachmentUploadError': 'Hata',
  'questionAdd.discardConfirmationConfirmButton': 'Sil',
  'questionAdd.discardConfirmationCancelButton': 'İptal',
  'questionAdd.discardConfirmationMessage':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Tüm değişiklikleri silmek istediğinize emin misiniz?',
  'questionAdd.saveConfirmationMessage':
    'Bu soruyu kaydetmek istediğinize emin misiniz? Devam ederseniz, soru taslak olarak kaydedilecektir.',
  'questionAdd.continue': 'Devam',
  'questionAdd.discardConfirmationTitle': 'Değişiklikleri göz ardı et',
  'questionAdd.continueTitle': 'Devam etmek istediğinize emin misiniz?',
  'questionAdd.lockConfirmationCancelButton': 'İptal',
  'questionAdd.lockConfirmationMessage':
    'Bu soruyu onaylamak ve kilitlemek istediğinize emin misiniz? Devam ederseniz, bu soru değerlendirmelerde kullanılabilir olacaktır.',
  'questionAdd.lockConfirmationSaveMessage':
    'Lütfen yaptığınız değişiklikleri kaydettiğinizden emin olun.',
  'questionAdd.maxOptionCharError':
    'Sorunun cevabı en fazla 400 karakter olabilir.',
  'questionAdd.attachmentError':
    'Yükleme işlemi tamamlanmayan ek(ler) bulunmaktadır.',
  'questionAdd.courseInfoTitle': 'Ders Bilgileri',
  'questionAdd.courseInfoSubtitle': 'Sorunun ekleneceği dersi seçin',
  'questionAdd.course': 'Ders',
  'questionAdd.selectCourseWarn':
    'Soru içeriğini ve detaylarını tanımlamak için bir ders seçiniz.',
  'questionAdd.loSelectionError':
    'Uyarı! Lütfen soruyu bir öğrenme hedefi ile ilişkilendirin.',
  'questionAdd.lockErrorToast':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Soruyu onaylayıp kilitlemeden önce değişiklikleri kaydedin.',
};
